<div class="dialogue-content">
  <div class="mat-dialogue-header">
    <h4 class="mat-dialog-title previewHeading" id="modal-basic-title">{{ heading }}</h4>
    <button type="button" class="btn-close" aria-label="Close" [mat-dialog-close]="false"></button>
  </div>
  <mat-dialog-content class="">
    <p class="confirmation" *ngIf="!isSession">{{LabelConstants.AreYouSure}}</p>
    <p class="confirmation" *ngIf="isSession">{{LabelConstants.AreYouSure}} cancel this session ?</p>
    <img *ngIf="ImageSrc" [src]="ImageSrc" alt="" class="profile-pic-delete"/>
    <p class="name WrapData" [title]="action">{{ action }} </p>
    <p class="name WrapData" *ngIf="date != '' || date != null">{{date}}</p>
  </mat-dialog-content>
  <div mat-dialog-actions class="dialog-actions dialogue-footer text-center mb-3">
    <button class="btn btn-primary-outline" [mat-dialog-close]="true"  >Yes</button>
    <button class="btn btn-primary" [mat-dialog-close]="false">{{LabelConstants.Cancel}}</button>
  </div>
</div>


