import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { commonLabelConstants } from 'src/app/constants/LabelConstants';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { CommonService } from 'src/app/services/common/common.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // Common Properties
  public focus: boolean = false;
  LabelConstants: any;
  UserName: any;
  UserId: any;
  UserImage: any;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toster: ToastrService,
    private apiUrl: ApiUrlHelper,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.UserName = this.storageService.getValue('userName');
    this.UserId = this.storageService.getValue('userId');
    this.UserImage = this.storageService.getValue('userImage');
    this.LabelConstants = commonLabelConstants;
    this.getUserDataById();
    this.commonService.getUserImage().subscribe(image => {
      if (image) {
        this.UserImage = image;
        localStorage.setItem('userImage', image);
      }
    });
  }

  //handle image error
  handleImageError() {
    this.UserImage = '../../../assets/img/default-user-profile.svg';
  }

  LogOut(action: any) {
    let apiUrl = this.apiUrl.apiUrl.Login.logout;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '460px',
      data: { action, heading : "Logout", isSession : false },
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.spinner.show();
        this.commonService.doPost(apiUrl, this.UserId).pipe().subscribe({
          next: (response) => {
            if (response.success) {
              this.router.navigate(['/login']);
              localStorage.clear();
              this.spinner.hide()
            }
            else {
              this.toster.error(response.message);
              this.spinner.hide()
            }
          },
          error: (err) => {
            this.spinner.hide();
            console.log(err);
          }
        })
      }
    });
  }

  NavigateToProfile() {
    this.router.navigate(['/myaccount'])
  }

  getUserDataById() {
    let api = this.apiUrl.apiUrl.User.GetUserById.replace('{Id}', this.UserId);
    this.commonService.doGet(api).pipe().subscribe({
      next: (response) => {
        if (response.success) {
          this.UserImage = response.data.images
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.log(err);
      }
    })
  }
}